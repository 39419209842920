<template>
  <div>
    <div class="nav clearfix productEnd">
      <h1>产品结果</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/match/index' }"
          >智能匹配</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策结果</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="topUsers">
        <el-select
          v-model="companyParams.province_id"
          placeholder="请选择省"
          @change="getcity"
        >
          <el-option
            v-for="item in province"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="companyParams.city_id"
          placeholder="请选择市"
          @change="gettown"
        >
          <el-option
            v-for="item in city"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="companyParams.town_id"
          placeholder="请选择区"
          @change="getstreet"
        >
          <el-option
            v-for="item in town"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          v-model="companyParams.keywords"
          placeholder="请输入政策名称关键字"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
      <el-table :data="this.company.data" v-if="isShow" style="width: 100%">
        <el-table-column
          prop="company_name"
          label="产品名称"
          :show-overflow-tooltip="true"
        >
          产品名称
        </el-table-column>
        <el-table-column
          prop="area"
          label="所属区域"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="companyParams.page"
          :page-size="companyParams.limit"
          layout=" prev, pager, next,total,jumper"
          :total="companyTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      isShow: false,
      labelPosition: "top",
      companyTotal: 0,
      companyParams: {
        page: 1,
        limit: 10,
        keywords: "",
        is_cooperation: 1,
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        order: "id desc",
      },
      options: [],
      province: [],
      city: [],
      town: [],
      street: [],
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  created() {
    this.getprovince();
    // this.$store.dispatch('getCompany', this.companyParams)
    setTimeout(() => {
      this.companyTotal = this.$store.state.company.total;
      this.isShow = true;
    }, 1000);
  },
  methods: {
    operatetype(row) {
      if (row.is_high === 1) {
        return "是";
      } else {
        return "否";
      }
    },
    handleSizeChange(val) {
      this.companyParams.limit = val;
      this.$store.dispatch("getCompany", this.companyParams);
    },
    handleCurrentChange(val) {
      this.companyParams.page = val;
      this.$store.dispatch("getCompany", this.companyParams);
    },
    handleSearch() {
      this.$store.dispatch("getCompany", this.companyParams);
      setTimeout(() => {
        this.companyTotal = this.$store.state.company.total;
      }, 1000);
    },
    getprovince() {
      // this.axios.get("/api/region/getProvince").then((res) => {
      //   this.province = res.data;
      //   this.$store.dispatch("getCompany", this.companyParams);
      //   setTimeout(() => {
      //     this.companyTotal = this.$store.state.company.total;
      //   }, 1000);
      // });
    },
    getcity(id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
          setTimeout(() => {
            this.companyTotal = this.$store.state.company.total;
          }, 1000);
        });
    },
    gettown(id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
          setTimeout(() => {
            this.companyTotal = this.$store.state.company.total;
          }, 1000);
        });
    },
    getstreet(id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
          setTimeout(() => {
            this.companyTotal = this.$store.state.company.total;
          }, 1000);
        });
    },
    handleDelete(id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.reload();
          this.$store.dispatch("getCompany", this.companyParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style></style>
